import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";

import "./assets/css/custom.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);
const Otp = React.lazy(() => import("./views/pages/login/Otp"));
const ForgotPassword1 = React.lazy(() =>
  import("./views/pages/login/ForgotPassword1")
);
class App extends Component {
  
  render() {
    return (
      <HashRouter>
        <ScrollTop />
        <React.Suspense fallback={loading}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/otp"
              name="Otp"
              render={(props) => <Otp {...props} />}
            />
            <Route
              exact
              path="/forgot-password1"
              name="Forgot Password1"
              render={(props) => <ForgotPassword1 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
